<template>
    <div class="footer">
        <hr class="separation-line" />
        <b-container :style="{ marginBottom: '39px' }">
            <b-row :style="{ marginBottom: '102px' }">
                <b-col sm="12" md="3">
                    <div>
                        <z-button
                            variant="primary"
                            @click="$router.push({ name: 'registrar-empresa' })"
                        >
                            Registrar mi empresa
                        </z-button>
                        <z-button
                            class="mt-2 mb-5"
                            variant="alice-blue"
                            @click="$router.push({ name: 'para-mensajeros' })"
                        >
                            Aplicar para repartidor
                        </z-button>
                    </div>
                </b-col>
                <b-col md="1"></b-col>
                <b-col sm="12" md="3">
                    <p class="label-title" align="left">
                        ZUBUT
                    </p>
                    <p class="label-options-p" align="left">
                        <router-link to="/" class="label-options">
                            Fulfillment
                        </router-link>
                    </p>
                    <p class="label-options-p" align="left">
                        <router-link :to="{ name: 'para-empresas' }" class="label-options">
                            Última milla
                        </router-link>
                    </p>
                    <p class="label-options-p" align="left">
                        <router-link :to="{ name: 'contact' }" class="label-options">
                            Contáctanos
                        </router-link>
                    </p>
                    <p class="label-options-p" align="left">
                        <a href="https://blog.zubut.com/" class="label-options">
                            Blog
                        </a>
                    </p>
                    <!-- <p class="label-options-p" align="left">
                        <a class="label-options" href="">
                            Únete al equipo
                        </a>
                    </p>
                    <p class="label-options-p mb-4" align="left">
                        <a class="label-options" href="">
                            Soporte
                        </a>
                    </p> -->
                </b-col>

                <b-col sm="12" md="3">
                    <p class="label-title" align="left">
                        REPARTIDORES
                    </p>
                    <p class="label-options-p" align="left">
                        <router-link to="/para-mensajeros#benefits" class="label-options">
                            Beneficios
                        </router-link>
                    </p>
                    <p class="label-options-p" align="left">
                        <router-link to="/para-mensajeros#earnings" class="label-options">
                            Ingresos
                        </router-link>
                    </p>
                    <p class="label-options-p" align="left">
                        <router-link to="/para-mensajeros#requisites" class="label-options">
                            Requisitos
                        </router-link>
                    </p>
                    <p class="label-options-p mb-4" align="left">
                        <router-link to="/para-mensajeros#testimonials" class="label-options">
                            Testimonios
                        </router-link>
                    </p>
                </b-col>
                <b-col sm="12" md="2">
                    <p class="label-title" align="left">
                        DESARROLLADORES
                    </p>
                    <p class="label-options-p" align="left">
                        <a class="label-options" href="https://api.zubut.com/doc/" target="_blank">
                            Documentación
                        </a>
                    </p>
                    <p class="label-options-p mb-4" align="left">
                        <router-link class="label-options" :to="{ name: 'para-desarrolladores' }">
                            Referencia API
                        </router-link>
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" sm="2" md="1">
                    <img class="logo-image" src="@/assets/img/zubut-icon.svg" alt="zubut logo" />
                </b-col>
                <b-col cols="12" sm="10" md="5">
                    <b-row>
                        <b-col>
                            <div align="left" class="info-links mb-2">
                                <div>
                                    <router-link
                                        class="label-options"
                                        :to="{ name: 'aviso-de-privacidad' }"
                                        >Aviso de privacidad
                                    </router-link>
                                </div>
                                <div>
                                    <router-link
                                        class="label-options"
                                        :to="{ name: 'terminos-y-condiciones' }"
                                    >
                                        Términos y condiciones
                                    </router-link>
                                </div>
                                <div>
                                    <router-link
                                        class="label-options"
                                        :to="{ name: 'politica-de-cookies' }"
                                    >
                                        Política de Cookies
                                    </router-link>
                                </div>
                                <!-- <router-link
                                    class="label-options ml-1"
                                    :to="{ name: 'contrato-prestacion-servicio' }"
                                >
                                    Contrato de Prestación de Servicio
                                </router-link> -->
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p align="left" class="label-zubut">
                                © {{ currentYear }} Delivering Geniality SAPI de CV
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="12" md="6">
                    <div class="social-media-links">
                        <a
                            href="https://www.facebook.com/zubut.delivery/"
                            target="_blank"
                            class="link-social"
                        >
                            <img src="@/assets/img/ic-fb.svg" alt="Facebook" />
                        </a>
                        <!-- <a
                            href=""
                            target="_blank"
                            class="link-social"
                        >
                            <img src="@/assets/img/ic-twt.svg" alt="Twiter" />
                        </a> -->
                        <a
                            href="https://www.instagram.com/zubut.delivery/"
                            target="_blank"
                            class="link-social"
                        >
                            <img src="@/assets/img/ic-ig.svg" alt="Instagram" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/zubut/"
                            target="_blank"
                            class="link-social"
                        >
                            <img src="@/assets/img/ic-in.svg" alt="LinkedIn" />
                        </a>
                        <a
                            href="https://angel.co/company/zubut-1"
                            target="_blank"
                            class="link-social"
                        >
                            <img src="@/assets/img/ic-angel.svg" alt="Angel" />
                        </a>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import getCurrentYear from "@/utils/getCurrentYear";
export default {
    name: "ZFooter",
    computed: {
        currentYear() {
            return getCurrentYear();
        }
    }
};
</script>

<style lang="scss" scoped>
.separation-line {
    margin-top: 0px;
    margin-bottom: 78px;
}

.label-title {
    color: $comet;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 22px;
}

.label-options-p {
    margin-bottom: 5px;
}

.label-options {
    color: #2c2c2c;
    font-size: 13px;
    font-weight: 300;
    line-height: 0px;
}

.links-pivacy-conditions {
    color: #293b51;
    font-size: 13px;
    font-weight: 300;
}

.label-zubut {
    color: #8190a1;
    font-size: 12px;
    line-height: 11px;
}

.link-social {
    margin-left: 20px;
    & > img {
        height: 22px;
        width: 22px;
    }
}
.logo-image {
    width: 32px;
    height: 37px;
}
.social-media-links {
    float: right;
}
.info-links > div {
    display: inline;
    &:not(:first-child) {
        margin-left: 5px;
    }
}
@media (max-width: 576px) {
    .info-links {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 16px 0;
        align-items: left;
        & > div {
            &:not(:first-child) {
                margin: 0;
            }
        }
    }
    .logo-image {
        float: left;
    }
}
@media (max-width: 680px) {
    .social-media-links {
        float: left;
    }
    .link-social:nth-child(1) {
        margin: 0;
    }
}
</style>
