<template>
    <div>
        <z-menu />
        <cookies-banner v-if="!acceptedCookies" />
        <b-container fluid class="no-gutters px-0">
            <router-view />
        </b-container>
        <z-footer />
    </div>
</template>

<script>
import ZMenu from "@/components/ZMenu";
import ZFooter from "@/components/ZFooter";
import CookiesBanner from "../components/CookiesBanner.vue";

export default {
    name: "MainLayout",
    components: {
        ZMenu,
        CookiesBanner,
        ZFooter
    },

    computed: {
        acceptedCookies() {
            return localStorage ? localStorage.acceptedCookies : false;
        }
    }
};
</script>
