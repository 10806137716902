<template>
    <div class="cookies-banner p-4" :class="acceptedCookies ? 'd-none' : ''">
        <div class="container d-lg-flex align-items-center justify-content-between">
            <p class="m-0">
                Zubut utiliza cookies para ofrecerte una mejor experiencia de usuario. Pedimos tu
                consentimiento y aceptación de las mencionadas cookies y de nuestra política de
                privacidad y cookies
                <router-link class="label-options ml-1" :to="{ name: 'politica-de-cookies' }">
                    Más informacion
                </router-link>
            </p>
            <div class="d-flex justify-content-between">
                <z-button class="ml-1" @click="handleClick">Aceptar</z-button>
            </div>
        </div>
    </div>
</template>

<script>
import ZButton from "./ZButton";

export default {
    name: "CookiesBanner",

    components: {
        ZButton
    },

    data() {
        return {
            acceptedCookies: false
        };
    },

    methods: {
        handleClick() {
            this.acceptedCookies = true;
            localStorage.acceptedCookies = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.cookies-banner {
    position: sticky;
    top: 60px;
    left: 0px;
    z-index: 2;
    background-color: $tangaroa;
    color: white;
    text-align: left;

    a {
        color: $maya-blue;

        &:hover,
        &:active,
        &:visited {
            text-decoration: none;
            color: $maya-blue;
        }
    }
}

@include media-breakpoint-up(lg) {
    .cookies-banner {
        top: 68px;
    }
}
</style>
