<template>
    <b-navbar sticky toggleable="xl" type="light">
        <b-container>
            <router-link class="navbar-brand" to="/">
                <img src="@/assets/img/zubut-logo-blue.png" alt="zubut logo" />
            </router-link>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse is-nav id="nav-collapse">
                <b-navbar-nav class="main-links z-text-small">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">
                            Zubut Fulfillment
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/para-empresas">
                            Última milla
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/para-mensajeros">
                            Repartidores
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/rastrea-tu-envio">
                            Rastrea envíos
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://blog.zubut.com/" target="_blank">
                            Blog
                        </a>
                    </li>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="https://app.zubut.com/inicio-sesion" target="_blank">
                        <z-button variant="alice-blue" class="nav-buttons">Iniciar sesión</z-button>
                    </b-nav-item>
                    <b-nav-item to="registrar-empresa">
                        <z-button class="nav-buttons">
                            Me interesa
                        </z-button>
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-container>
    </b-navbar>
</template>

<script>
import ZButton from "./ZButton.vue";
import trackActionWithAnalytics from "@/utils/track-Action-With-Analytics.js";
import { BCollapse } from "bootstrap-vue";

export default {
    name: "ZMenu",

    components: {
        BCollapse,
        ZButton
    },

    methods: {
        handleClick() {
            trackActionWithAnalytics.facebook("Lead", "registrar empresa");
            trackActionWithAnalytics.google("link", "registrar empresa");
        }
    }
};
</script>

<style lang="scss" scoped>
$light-blue: #3c97ff;
$zubut-blue: #0077fe;
.nav-buttons {
    max-width: 132px;
}
.nav-item {
    font-weight: 500;
}
.navbar {
    border-bottom: 1px solid #efefef;
    background: #fff;
    text-align: left;

    .navbar-brand {
        img {
            width: 136px;
            height: 33px;
        }
    }

    .nav-link {
        position: relative;
        color: #1c1c1c !important;

        &:hover {
            font-weight: 600;
        }
    }

    .main-links {
        .nav-link {
            &:hover {
                &::before {
                    content: "";
                    position: absolute;
                    width: 4px;
                    height: 4px;
                    top: 10px;
                    right: 0px;
                    border-radius: 50%;
                    background: $zubut-blue;
                }
            }
        }
    }

    .btn-phone {
        background-color: $lavender !important;
        color: $zubut-blue !important;
    }
}
</style>
